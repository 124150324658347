import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { SiConfig, SiConfigProvider } from '@sisuite/ui';
import { map } from 'rxjs/operators';
import { EnvironmentSettings, EnvironmentSettingsResponse, IdentityProviderConfig } from '../environment-settings';
import { BrowserTracing } from '@sentry/browser';

@Injectable()
export class ConfigService extends SiConfigProvider {
  private config: EnvironmentSettings = {
    landingpageTitle: '',
    landingpageSubtitle: '',
    companyLogo: '',
    companyUrl: '',
    authServerUrl: '',
    dashboardUrl: '',
    customLoginUrl: '',
    requestDemoUrl: '',
    authCookieDomain: '',
    authCookieName: ''
  };
  private isBrowser: boolean;
  constructor(
    private readonly http: HttpClient,
    @Inject(PLATFORM_ID) platformId: any,
    @Inject('environment') private environment: any
  ) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  async init(): Promise<void> {
    try {
      this.config = await this.http
        .get<EnvironmentSettingsResponse>('assets/settings.json')
        .pipe(map(res => this.parseResponse(res)))
        .toPromise();
    } catch (error) {
      console.error(error);
    }

    if (this.isBrowser) {
      this.initSentry();
    }
  }

  getProviderByEmail(email: string): IdentityProviderConfig | undefined {
    const idpConfig = this.config.customIdpConfig;
    const idps = idpConfig?.filter(x => email.endsWith(x.emailDomain)) ?? [];
    return idps[0];
  }

  parseResponse(response: EnvironmentSettingsResponse): EnvironmentSettings {
    const { customIdpConfig, ...rest } = { ...response };
    const settings: EnvironmentSettings = rest;
    try {
      settings.customIdpConfig = JSON.parse(customIdpConfig ? customIdpConfig : '[]');
    } catch (ex) {
      if (typeof (customIdpConfig) === 'object') {
        settings.customIdpConfig = customIdpConfig;
      }
      else {
        console.error('Could not parse custom idp config', customIdpConfig, ex);
      }
    }
    return settings;
  }

  initSentry(): void {
    const dsn =
      this.environment.sentryDsn !== undefined
        ? this.environment.sentryDsn
        : this.config.sentryDSN;
    const env =
      this.environment.sentryEnv !== undefined
        ? this.environment.sentryEnv
        : this.config.sentryEnv;
    // Enable tracing if sentry DSN and Environment is set
    const autoSessionTracking = !!env && !!dsn;
    // Make sure to set the sampling rate to 0 is autoSessionTracking is off
    const tracesSampleRate = autoSessionTracking ? 0.5 : 0;

    Sentry.init({
      dsn,
      environment: env,
      release: this.config.version,
      autoSessionTracking,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate,
    });
  }

  getConfig(): EnvironmentSettings {
    return this.config;
  }

  get configuration(): SiConfig {
    return {
      dashboardUrl: this.config.dashboardUrl,
      cookieDomain: this.environment.authCookieDomain || this.config.authCookieDomain,
      securityManagerNavigationUrl: ''
    };
  }
}
